module.exports = [{
      plugin: require('../../../themes/paws/gatsby-browser.tsx'),
      options: {"plugins":[],"campaignId":"","brandId":"1CB41F24-66EA-43ED-A2A2-BB8C96A4DC48","country":"IE"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/opt/build/repo/packages/themes/agria/src/images/icon-512x512.png","icon_options":{"purpose":"any maskable"},"name":"Agria Pet Insurance","short_name":"Agria","start_url":"/","background_color":"#02315B","theme_color":"#02315B","display":"minimal-ui","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1864b9fcc160722ec00366f136cbe20f"},
    },{
      plugin: require('../../../themes/agria/gatsby-browser.tsx'),
      options: {"plugins":[],"country":"IE","currency":"EUR"},
    },{
      plugin: require('../../../themes/qab/gatsby-browser.tsx'),
      options: {"plugins":[],"companyName":"Agria Petinsure","contactNumber":"021 202 9119","footerTerms":"Capstone Financial Services Limited trading as Petinsure and Agria Petinsure is regulated by the Central Bank of Ireland. Directors: Bernard O’Sullivan, Agnes Fabricius (Sweden) & Monica Tuvelid (Sweden). Registered in Ireland with registration number 451193. Agria Petinsure policies are underwritten by Försäkringsaktiebolaget Agria (publ), c/o Petinsure, PO Box 911, Little Island, Cork, T45 HP92, Ireland. Försäkringsaktiebolaget Agria (publ) is regulated by Finansinspektionen, the Swedish Financial Supervisory Authority and the Central Bank of Ireland for conduct of business rules.","trustpilotUrl":"https://www.trustpilot.com/review/agriapetinsure.ie","allowedSpecies":["Cat","Dog"],"petOrAnimal":"pet","defaultPromoCode":"ONLINE10PERC"},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
